import React from 'react';
import classnames from 'classnames';

import Typography from '@material-ui/core/Typography';

import { makeStyles } from '@material-ui/core/styles';

const BtnItem = ({ color, length, item, onClick, active }) => {
    const classes = useStyles({ color: color, length: length });

    return (
        <div className={classnames(classes.btn, active && 'active')} onClick={onClick}>
            <div
                className={classes.img}
                style={{ backgroundImage: 'url(/img/' + item.after + ')' }}
            />
            <div className={classes.overlay} />
            <Typography className={classes.title} gutterBottom variant='h6' component='p'>
                {item.title}
            </Typography>
        </div>
    );
};

const useStyles = makeStyles((theme) => ({
    btn: {
        position: 'relative',
        borderBottom: ({ color }) => '3px solid ' + color,
        '&:hover $overlay, &.active $overlay': {
            opacity: 0.5,
        },
        height: ({ length }) => 100 / length + '%',
        minHeight: 100,
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
        '&:last-child': {
            borderBottom: 'none',
        },
    },
    img: {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundPosition: 'center center',
    },
    overlay: {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        background: 'linear-gradient(90deg, rgba(0,0,0,0.8) 0%, rgba(0,0,0,0.2) 100%)',
        transition: 'opacity 0.3s ease-in',
    },
    title: {
        color: '#fff',
        position: 'relative',
        padding: '2rem 0.5rem',
        cursor: 'pointer',
        margin: 0,
        width: '100%',
    },
}));

export default BtnItem;
