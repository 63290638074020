import React from 'react';
import { Route, Switch } from 'react-router-dom';

import Layout from 'view/layout/Layout';
import Main from 'view/page/Main';
import NotFound from 'view/page/404';

function App() {
    return (
        <Layout>
            <Switch>
                <Route path='/:orderId'>
                    <Main />
                </Route>
                <Route error='404'>
                    <NotFound />
                </Route>
            </Switch>
        </Layout>
    );
}

export default App;
