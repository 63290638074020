import React from 'react';

import { makeStyles } from '@material-ui/core/styles';

import Theme from './Theme';

const Layout = ({ children }) => {
    const classes = useStyles();
    return (
        <Theme>
            <div className={classes.container}>{children}</div>
        </Theme>
    );
};

const useStyles = makeStyles((theme) => ({
    container: {
        minWidth: 250,
        minHeight: '100vh',
        overflow: 'hidden',
        position: 'relative',
    },
}));

export default Layout;
