import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

const Help = () => {
    const classes = useStyles();
    const [help, setHelp] = React.useState(false);

    return (
        <React.Fragment>
            <div className={classes.btn} onClick={() => setHelp(true)}>
                ?
            </div>
            <Dialog onClose={() => setHelp(false)} open={help} className={classes.dialog}>
                <DialogTitle>How To Use</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Select a room style from the menu on the right.
                        <br />
                        Drag the vertical bar on top of the before and after images to reveal them.
                    </DialogContentText>
                    <img
                        src='/img/logo/help2.gif'
                        alt='example of how to use this tool'
                        className={classes.img}
                    />
                </DialogContent>
            </Dialog>
        </React.Fragment>
    );
};

const useStyles = makeStyles((theme) => ({
    btn: {
        position: 'absolute',
        bottom: 0,
        left: 0,
        width: 50,
        height: 50,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        fontSize: '1.8rem',
        fontWeight: 700,
        color: ({ color }) => color,
        borderTopRightRadius: '50%',
        backgroundColor: 'rgba(255,255,255,0.4)',
        cursor: 'pointer',
    },
    dialog: {
        textAlign: 'center',
    },
    img: {
        width: '100%',
    },
}));

export default Help;
