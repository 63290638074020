import Request from 'util/Request';

export const fetch = (orderId) => {
    return Request.post('site/index', {
        search: {
            ref: orderId,
        },
    }).then((res) => {
        return res.data.valid ? res.data.data : false;
    });
};
