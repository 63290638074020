function notFound() {
  return (
    <div style={style.container}>
      <div style={style.dom}>
        <center>
          <h1 style={style.h1}>404</h1>
          <h2 style={style.heading}>Opps! Page not found</h2>
          <p style={style.heading}>
            Sorry but the page you are looking for is not found. Please make
            sure you typed the correct URL
          </p>
        </center>
      </div>
    </div>
  );
}

const style = {
  container: {
    maxWidth: "768px",
    height: "100vh",
    display: "flex",
    justyfyContent: "center",
    alignItems: "center",
    marginLeft: "auto",
    marginRight: "auto",
  },
  dom: {
    margin: "0px",
    marginLeft: "auto",
    marginRight: "auto",
  },
  heading: {
    margin: "0px",
    paddingLeft: "10px",
    paddingRight: "10px",
  },
  h1: {
    margin: "0px",
    paddingLeft: "10px",
    paddingRight: "10px",
    color:'orange',
    fontSize:'50px'
  },
};

export default notFound;
