import axios from 'axios';

import Config from 'config/Config';

const Request = axios.create({
    baseURL: Config.url.api,
    timeout: 30000, //30sec
});

Request.interceptors.request.use((config) => {
    //const token = UAuth.get().token;
    //config.headers.Authorization = 'Bearer ' + token;

    return config;
});

Request.interceptors.response.use((response) => {
    return response;
});

export default Request;
