const config = {
    google: {
        analytics: 'G-P9MZEDG3E5',
    },
    path: {
        img: '/img',
    },
    url: {
        api: 'https://roomstyle.propertystudios.co.uk/api/',
    },
    version: 'v0.0.1',
};

if (process.env.NODE_ENV === 'development') {
    config.google.analytics = '';
    config.url.api = 'http://localhost/ps-room-style/api/';
}

export default config;
