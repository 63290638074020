import React from 'react';
import ReactCompareImage from 'react-compare-image';
import { Scrollbars } from 'react-custom-scrollbars';
import { useParams } from 'react-router-dom';

import Grid from '@material-ui/core/Grid';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';

import { makeStyles } from '@material-ui/core/styles';

import * as UProject from 'util/Project';
import Help from 'view/component/Help';
import ImgBtn from 'view/component/ImgBtn';
import NotFound from "./404";

const Main = () => {
    const { orderId } = useParams();

    const [images, setImages] = React.useState([]);
    const [brand, setBrand] = React.useState({
        primary: '#eba847',
        accent: '#eba847',
    });
    const [isLoading, setLoading] = React.useState(true);
    const [img, setImg] = React.useState(0);
    const classes = useStyles({ brand: brand });

    React.useEffect(() => {
        async function getData() {
            const project = await UProject.fetch(orderId);
            if (project) {
                setImages(project.rooms);
                setBrand(project.colors);
            }
            setLoading(false);
        }
        getData();
    }, []);

    if (!isLoading && images.length > 0) {
        return (
            <React.Fragment>
                <Grid container>
                    <Grid item xs={12} md={9} className={classes.left}>
                        <ReactCompareImage
                            leftImage={'/img/' + images[img].before}
                            rightImage={'/img/' + images[img].after}
                            sliderLineWidth={3}
                            handleSize={80}
                            sliderPositionPercentage={0.3}
                            sliderLineColor={brand.primary}
                        />
                        <div className={classes.select}>
                            <Select value={img} onChange={(e) => setImg(e.target.value)}>
                                {images.map((item, key) => {
                                    return (
                                        <MenuItem key={key} value={key}>
                                            {item.title}
                                        </MenuItem>
                                    );
                                })}
                            </Select>
                        </div>
                    </Grid>
                    <Grid item md={3} className={classes.btns}>
                        <Scrollbars autoHide>
                            {images.map((item, key) => {
                                return (
                                    <React.Fragment key={key}>
                                        <ImgBtn
                                            item={item}
                                            length={images.length}
                                            color={brand.primary}
                                            onClick={() => setImg(key)}
                                            active={img === key}
                                        />
                                    </React.Fragment>
                                );
                            })}
                        </Scrollbars>
                    </Grid>
                </Grid>

                <Help />
            </React.Fragment>
        );
    } else if (isLoading) {
        return <p>Loading...</p>;
    } else {
        return <NotFound />;
    }
};

const useStyles = makeStyles((theme) => ({
    btns: {
        borderLeft: ({ brand }) => '3px solid ' + brand.primary,
        backgroundColor: '#000',
        [theme.breakpoints.down('sm')]: {
            display: 'none',
        },
    },

    left: {
        position: 'relative',
        height: '100vh',
        backgroundColor: '#2f2e2e',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    select: {
        position: 'absolute',
        top: 0,
        right: 0,
        background: 'rgba(255,255,255,0.6)',
        borderBottomLeftRadius: 15,
        borderBottom: '2px solid #eba847',
        borderLeft: '2px solid #eba847',
        paddingLeft: 5,
        overflow: 'hidden',
        '& .MuiInput-root': {
            fontWeight: theme.typography.fontWeightBold,
        },
        '& .MuiInput-root:before, & .MuiInput-root:after': {
            borderColor: 'transparent !important',
        },
        [theme.breakpoints.up('md')]: {
            display: 'none',
        },
    },
}));

export default Main;
